/* .App {
  text-align: center;
} */



* {
  font-family: "Ubuntu", sans-serif;
}

.all-bg-color {
  background: #6E2585;
}

.custom-navbar {
  background-color: white;
  /* Adjust background color as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Adds a slight shadow for depth */
}

.logo {
  height: 50px;
  /* padding: 5px 10px; */
  /* Adjust logo size */
}

a{
  text-decoration: none !important;
}
/* ---------- */

/* Landing Baner */
.landing-banner {
  padding-top: 100px;
  /* Adjust to match the navbar height */
  position: relative;
  background-color: #f8f9fa;
  padding-bottom: 50px;
  /* Space at the bottom for design */
}

/* Decorative bottom design */
.landing-banner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(135deg, #6E2585 0%, #4e1864 100%);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* Heading Section Styling */


.banner-left-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 80px;
}

.heading-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}

.custom-heading1 {
  text-transform: uppercase;
  color: #6e2585;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.5;
}

.custom-heading2 {
  color: #6f2585de;
  font-size: 28px;
  font-weight: 600;
}

.button-container {
  margin-top: 10px;
  /* Space between headings and button */
}

.call-button {
  display: inline-block;
  background-color: #6e2585;
  /* Violet background */
  color: #fff;
  padding: 15px 25px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.call-button:hover {
  background-color: #57206f;
}


.banner-btn {
  cursor: pointer;
  position: relative;
  padding: 10px 24px;
  font-size: 18px;
  color: #57206f;
  border: 2px solid #57206f;
  border-radius: 34px;
  background-color: transparent;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.banner-btn::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: #57206f;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.banner-btn:hover::before {
  scale: 3;
}

.banner-btn:hover {
  color: white;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98, 0.4);
}

.banner-btn:active {
  scale: 1;
}




.text-box-div {
  margin-top: 50px;
}

.text-box {
  background-color: #6E2585;
  color: white;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  margin-bottom: 10px;
}

.text-box:hover {
  transform: translateX(5px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}



/* .img-fluid {
  max-width: 80%;
  height: auto;
} */

/* Responsive adjustments */
@media (max-width: 768px) {
  .landing-banner {
    padding-top: 100px;
  }

  .text-box {
    font-size: 1.2rem;
    padding: 10px 15px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .text-box {
    font-size: 1rem;
    padding: 8px 10px;
  }
}


/* ----------- */

/* Container for product cards */
.container {
  padding: 20px;
}

/* Product card styling */
.product-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* height: 100%; */
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* Product image styling */
.product-image {
  width: 80%;
  /* max-height: 300px; */
  /* Limit height for a consistent look */
  /* object-fit: cover; */
  padding: 20px;
}

/* Card title styling */
.product-card-title {
  font-size: 26px;
  font-weight: 600;
  color: #333;
  margin: 30px 0;
}

/* "Shop Now" button styling */


.shop-now-btn {
  background-color: #6E2585;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 15px 25px;
  border-radius: 30px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
  letter-spacing: 0.5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.shop-now-btn:hover {
  background-color: #6a1683;
  transform: scale(1.05);
}

.shop-now-btn:active {
  transform: scale(0.95);
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .product-image {
    width: 90%;
  }
}

/* ------------- */

.icon-container {
  font-size: 2rem;
  /* Adjust icon size */
  color: white;
  /* Icon color, change as needed */
}

.feature-div {
  transition: 0.5s ease-in-out;
}

.feature-div:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transform: translateY(-5px);
}

.feature-text {
  font-weight: 600;
  font-size: 20px;
  color: white;
}

/* Responsive alignment for smaller screens */
@media (max-width: 768px) {
  .icon-container {
    font-size: 1.25rem;
  }

  .feature-text {
    font-size: 0.9rem;
  }
}

/* -------------- */

.parallax-container {
  position: relative;
  height: 500px;
  /* Adjust height as needed */
  background-image: url('./assets/callbanner-img.webp');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 80px !important;

}

.parallax-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(111, 37, 133, 0.342);
  /* Darker overlay for better readability */
  z-index: 1;
}

.parallax-banner-content {
  position: relative;
  z-index: 2;
  /* Ensures content is above the overlay */
  color: white;
  max-width: 400px;
  text-align: left;
}

.parallax-banner-content h4,
.parallax-banner-content h2 {
  margin: 15px 0;
}

.parallax-banner-content h4 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.parallax-banner-content h2 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .parallax-container {
    padding: 0 20px;
    height: 400px;
    background-attachment: scroll;
    /* Disable fixed attachment on smaller screens for smoother performance */
  }

  .parallax-banner-content {
    max-width: 100%;
    text-align: center;
    /* Center text on smaller screens */
  }

  .parallax-banner-content h4 {
    font-size: 22px;
  }

  .parallax-banner-content h2 {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .parallax-container {
    height: 300px;
  }

  .parallax-banner-content h4 {
    font-size: 18px;
  }

  .parallax-banner-content h2 {
    font-size: 24px;
  }
}

/* ------------------ */
.footer-container {
  background-color: #6E2585;
  color: #ffffff;
  padding: 20px 0;
}

.footer-container a {
  color: #ffffff;
  text-decoration: none;
}

.footer-container a:hover {
  color: #ffffffae;
  text-decoration: none;
}

.list-unstyled li {
  transition: 0.3s ease-in-out;
}

.list-unstyled li:hover {
  transform: translateX(5px);
}

.footer-container h5 {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.footer-container ul {
  padding: 0;
  list-style: none;
}

.footer-container ul li {
  margin-bottom: 10px;
}

.footer-container .fa {
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-container {
    text-align: center;
  }

  .footer-container h5 {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .footer-container h5 {
    font-size: 1rem;
  }

  .footer-container .fa {
    font-size: 0.9rem;
  }
}

/* ------------------ */
.footer-banner {
  background-color: #6E2585;
  color: white;
  text-align: center;
  padding: 15px 10px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  width: 100%;
  z-index: 1000;
}

.footer-banner-text {
  display: inline-block;
  font-size: 30px;
}

.phone-icon {
  margin-right: 20px;
  margin-left: 20px;
}

@media (max-width: 768px) {

  /* Fix the banner to the bottom for mobile screens */
  .footer-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
  }

  /* Show only the phone number and icon on mobile */
  .phone-icon {
    font-size: 30px;
  }

  .phone-number {
    font-size: 25px;
  }

  /* Hide the "Need Assistance? Dial" text */
  .footer-banner .full-text {
    display: none;
  }
}


/* -------------- */


/* POLICIES */

.policy-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.policy-header {
  color: #6e2585;
  font-weight: 600;
}

.policy-intro,
.policy-section {
  color: #333;
}

.policy-section {
  margin-bottom: 20px;
}

.policy-title {
  color: #6e2585;
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}

.policy-section h3 {
  color: #444;
  font-size: 20px;
  margin-top: 15px;
}

.policy-section p {
  line-height: 1.6;
}

/* -------------- */

/* AboutUs.css */

.about-container {
  padding: 50px 20px;

}

.about-header {
  text-align: center;
  margin-bottom: 40px;
  color: #343a40;
}

.about-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #6a1683;
}

.about-content {
  margin-bottom: 30px;
}

.about-image {
  width: 80%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.about-section-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #6a1683;
}

.section-text {
  font-size: 16px;
  color: #555;
  text-align: justify;
}



.values {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.value-card {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  min-width: 220px;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.value-card h3 {
  font-size: 25px;
  font-weight: 600;
  color: #6a1683;
  margin-bottom: 10px;
}

.value-card p {
  font-size: 16px;
}

/* ------------------------ */

.contact-us {
  padding: 40px 20px;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #6a1683;
  margin-bottom: 20px;
}

.contact-info {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-box {
  background-color: #f9f9f9;
  padding: 20px;
  width: 280px !important;
  margin: 10px 15px;
  min-height: 170px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.contact-box h5 {
  font-size: 20px;
  font-weight: 600;
}

.contact-box p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.contact-box:hover {
  transform: translateY(-10px);
  background-color: #eef3ff;
}

.contact-icon {
  font-size: 2rem;
  color: #6a1683;
  margin-bottom: 10px;
}

.contact-main {
  margin-top: 40px;
}

.contact-image {
  width: 80%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-subtitle {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: #6a1683;
}

.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form label {
  font-weight: 600;
}

.contact-form .btn {
  background-color: #6a1683;
  border: none;
  font-weight: bold;
}

.additional-info {
  font-size: 1.1rem;
  color: #555;
}

/* ------------- */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #764ba2, #6a1683);
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.not-found-title {
  font-size: 8rem;
  font-weight: 700;
  margin: 0;
  color: #ff6b6b;
  text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.not-found-subtitle {
  font-size: 1.5rem;
  margin-top: 10px;
  font-weight: 500;
  color: #ffffff;
}

.not-found-description {
  font-size: 1.1rem;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #e0e0e0;
  max-width: 500px;
}

.back-home-button {
  text-decoration: none;
  color: #ffffff;
  background-color: #ff6b6b;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.back-home-button:hover {
  background-color: #ff3b3b;
  transform: translateY(-3px);
}